@mixin button {
    background-color: #235B2D;
    color: #FFFFFF;
    border: #235B2D solid 2px ;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 35px;
    padding-right: 35px;
    
}

.userPlants{
    display: flex;
    margin: auto 0%;
    justify-content: center;
    margin-top: 30px;

    .userSec{
   background-color: #C4C4C4;
   width: 80%;
   padding: 10px;
   padding-left: 20px;
   padding-right: 20px;
   margin-bottom: 40px;

    
        .plantsNav{
            display: flex;
            margin: auto 0%;
            justify-content: space-between;
            margin-left: 10px;
            margin-right: 10px;

            .select{
                width: 30%;
                border: white solid 2px;

                option:hover{
                    background-color: yellow;
                }

            }
            button{
                @include button();
                width: 20%;
            }

        }
        .plantSelect{
            display: flex;
            margin: auto 0%;
            flex-wrap: wrap;
            justify-content: space-evenly;

            .planCard{
                
                width: 17%;
                padding: 5px;
                margin: 5px;
                p{
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                .plantImg{
                    width: 90%;
                    border-radius: 5px;
                }
            }

        }
    }
}

.selected{
    .planCard{
        
        .plantImg{
            border: 2px solid #235B2D;
        }
    }
}
.selected-plant{
    border: 2px solid #235B2D;
}